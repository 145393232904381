import React, { memo } from 'react';
import CloseIcone from '../../../../svg/closeIcone';

interface IProps {
  title?: string;
  color?: string;
  isVisible: boolean;
}

const ChatHeader = ({
  color = '#9966ff',
  title = 'Welcome to ioni chatbot!',
  isVisible,
}: IProps) => {
  const onPressCloseChat = () => {
    if (window.parent) {
      window.parent.postMessage(
        { type: 'buttonClicked', data: { isVisible: !isVisible } },
        '*',
      );
    }
  };

  return (
    <div
      className="chat-header"
      style={{ backgroundColor: color ? color : '#9966ff' }}>
      <p className="header-text">
        {title ? title : 'Welcome to ioni chatbot!'}
      </p>
      <button className="header-button" onClick={onPressCloseChat}>
        <CloseIcone />
      </button>
    </div>
  );
};

export default memo(ChatHeader);
