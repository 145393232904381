import { AxiosResponse } from 'axios';
import axiosInstance, {
  RequestResponse,
  ResponseError,
  ResponseSuccess,
} from '../../configs/axios';
import { ConversationMessageRequest, ConversationRequest } from './types';

const parseResponse = <T>(response: AxiosResponse<T>) => {
  let data: RequestResponse<T> = {
    kind: ResponseError,
    message: response.statusText,
    status: response.status,
    body: response.data,
  };

  if (response.status >= 200 && response.status < 400) {
    data = {
      kind: ResponseSuccess,
      body: response.data,
      status: response.status,
    };
  }

  return data;
};

export const postMessageHandler = async <T>(
  body: ConversationMessageRequest,
): Promise<RequestResponse<T>> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.post<T>(
      'public/chat',
      body,
    );

    return parseResponse(response);
  } catch (error: any) {
    console.error('postMessageHandler -->', error);
    return {
      kind: ResponseError,
      message: error.message,
      body: error.response.data,
    };
  }
};

export const getChatConfigurations = async <T>(
  accountId: string,
  agentId: string | null,
): Promise<RequestResponse<T>> => {
  try {
    const agentQuery =
      !agentId || agentId === 'undefined' ? '' : `?agentId=${agentId}`;

    const response = await axiosInstance.get(
      `public/chat-settings/${accountId}${agentQuery}`,
    );

    return parseResponse(response);
  } catch (error: any) {
    console.error('getChatConfigurations -->', error);
    return {
      kind: ResponseError,
      message: error.message,
    };
  }
};

export const getConversationById = async <T>(
  body: ConversationRequest,
): Promise<RequestResponse<T>> => {
  try {
    const response = await axiosInstance.post('public/chat', body);

    return parseResponse(response);
  } catch (error: any) {
    console.error('getConversationById -->', error);
    return {
      kind: ResponseError,
      message: error.message,
    };
  }
};
