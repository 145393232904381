export const getStorageItemByName = (name: string) => {
  if (!name) return null;

  return localStorage.getItem(name);
};

export const setStorageItemByName = (name: string, item: string) => {
  if (!name || !item) return null;

  localStorage.setItem(name, item);
};
