import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import Chat from '../Chat/Chat';
import useAppReducer from '../../hooks/useAppReducer';
import 'react-toastify/dist/ReactToastify.css';
import { AppStorageContext } from '../../context/useAppStorageContext';

interface AppProps {
  children?: ReactNode;
}

function App({ children }: AppProps) {
  const { state, dispatch } = useAppReducer();

  return (
    <AppStorageContext.Provider value={{ state, dispatch }}>
      <div className="App">
        <Chat />
        <ToastContainer />
        {children}
      </div>
    </AppStorageContext.Provider>
  );
}

export default App;
