type WebVitalsMetric = (onPerfEntry: (entry: any) => void) => void;

interface WebVitals {
  getCLS: WebVitalsMetric;
  getFID: WebVitalsMetric;
  getFCP: WebVitalsMetric;
  getLCP: WebVitalsMetric;
  getTTFB: WebVitalsMetric;
}

const reportWebVitals = (onPerfEntry?: (entry: any) => void): void => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then(
      ({ getCLS, getFID, getFCP, getLCP, getTTFB }: WebVitals) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      },
    );
  }
};

export default reportWebVitals;
