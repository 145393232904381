import React, { memo } from 'react';
import { ConversationContentItem } from '../../../../api/chat/types';

interface IProps {
  content: string | ConversationContentItem[];
}

const CustomMessage = ({ content }: IProps) => {
  let messageContent = null;

  if (typeof content === 'string') {
    messageContent = content;
  }

  if (Array.isArray(content)) {
    const messageText = content.find((el) => el.type === 'text');

    messageContent = (
      <div>{messageText?.text ? <div>{messageText.text}</div> : null}</div>
    );
  }

  return <div>{messageContent}</div>;
};

export default memo(CustomMessage);
