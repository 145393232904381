const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 27 27">
      <path
        fill="transparent"
        strokeWidth="2"
        stroke="hsl(0, 0%, 99%)"
        strokeLinecap="round"
        d="M 7 7 L 20 20"></path>
      <path
        fill="transparent"
        strokeWidth="2"
        stroke="hsl(0, 0%, 99%)"
        strokeLinecap="round"
        d="M 7 20 L 20 7"></path>
    </svg>
  );
};

export default CloseIcon;
