import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getChatConfigurations,
  getConversationById,
} from '../../api/chat/chat';
import { getStorageItemByName } from '../../services/localStorage';
import Messages from './components/Messages/Messages';
import ChatHeader from './components/ChatHeader/ChatHeader';
import MessageInput from './components/MessageInput/MessageInput';
import { useAppStorageContext } from '../../context/useAppStorageContext';
import {
  ChatConfiguration,
  ConversationMessageResponse,
} from '../../api/chat/types';
import './chat.css';

const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get('accountId');
  const agentId = urlParams.get('agentId');

  return {
    accountId,
    agentId,
  };
};

const sendWindowParentMessage = (type: string, payload?: unknown) => {
  if (!type) return;
  window.parent.postMessage({ type, data: payload }, '*');
};

const Chat = () => {
  /** Context data */
  const { state, dispatch } = useAppStorageContext();
  const { title, color, isVisible, messages } = state;

  useEffect(() => {
    const { accountId, agentId } = getUrlParams();
    if (accountId) {
      getChatConfigurations<ChatConfiguration>(accountId, agentId).then(
        (res) => {
          const result = res?.body;

          if (result) {
            const { title, color, position, defaultOpenType, welcomeMessage } =
              result;

            if (title) {
              dispatch({ type: 'TITLE', payload: result.title });
            }

            if (color) {
              dispatch({ type: 'COLOR', payload: `#${color}` });
              sendWindowParentMessage('colorChanged', { color: `#${color}` });
            }

            if (position) {
              dispatch({ type: 'POSITION', payload: position });
              sendWindowParentMessage('position', { position });
            }

            if (defaultOpenType) {
              let isVisible;

              if (defaultOpenType === 'desktopOnly') {
                isVisible = window?.screen?.width > 590;
              } else isVisible = defaultOpenType === 'all';

              dispatch({ type: 'IS_VISIBLE', payload: isVisible });
              sendWindowParentMessage('defaultOpenType', { isVisible });
            }

            if (welcomeMessage) {
              dispatch({ type: 'WELCOME_MESSAGE', payload: welcomeMessage });
              dispatch({
                type: 'UPDATE_WELCOME_MESSAGE',
                payload: {
                  role: 'assistant',
                  content: welcomeMessage,
                  type: 'WELCOME_MESSAGE',
                },
              });
              if (!welcomeMessage) {
                dispatch({
                  type: 'DELETE_WELCOME_MESSAGE',
                });
              }
            }
          }

          sendWindowParentMessage('chatLoad');
        },
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      const result: ChatConfiguration = event.data;

      if (result) {
        const {
          title,
          color,
          position,
          defaultOpenType,
          isVisible,
          welcomeMessage,
        } = result;

        if (title) {
          dispatch({ type: 'TITLE', payload: title });
        }

        if (color) {
          dispatch({ type: 'COLOR', payload: color });
          sendWindowParentMessage('colorChanged', { color: `#${color}` });
        }

        if (position) {
          dispatch({ type: 'POSITION', payload: position });
          sendWindowParentMessage('position', { position });
        }

        if (defaultOpenType) {
          let isVisible;

          if (defaultOpenType === 'desktopOnly') {
            isVisible = window?.screen.width > 590;
          } else isVisible = defaultOpenType === 'all';

          dispatch({ type: 'IS_VISIBLE', payload: isVisible });
          sendWindowParentMessage('defaultOpenType', { isVisible });
        }

        if (Object.keys(result).includes('welcomeMessage')) {
          dispatch({ type: 'WELCOME_MESSAGE', payload: welcomeMessage });
          dispatch({
            type: 'UPDATE_WELCOME_MESSAGE',
            payload: {
              role: 'assistant',
              content: welcomeMessage,
              type: 'WELCOME_MESSAGE',
            },
          });
          if (!welcomeMessage) {
            dispatch({
              type: 'DELETE_WELCOME_MESSAGE',
            });
          }
        }

        if (Object.keys(result).includes('isVisible')) {
          dispatch({ type: 'IS_VISIBLE', payload: isVisible });
        }
      }
    };

    window.addEventListener('message', handleMessageEvent);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [dispatch]);

  useEffect(() => {
    const { accountId } = getUrlParams();
    const conversationId = getStorageItemByName('conversationId');

    if (accountId && conversationId) {
      getConversationById<ConversationMessageResponse>({
        accountId,
        conversationId,
      }).then((response) => {
        if (response.kind === 'success' && response.body) {
          dispatch({ type: 'MESSAGES', payload: response.body.messages });
        } else {
          toast(`${response.message}: ${response.body?.message}`, {
            type: 'error',
          });
        }
      });
    }
  }, [dispatch]);

  return (
    <div>
      <div className="chat-container">
        <ChatHeader title={title} color={color} isVisible={isVisible} />
        {messages?.length > 0 && (
          <Messages isVisible={isVisible} messages={messages} />
        )}
        <MessageInput color={color} />
      </div>
    </div>
  );
};

export default Chat;
