import React, { memo } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

interface IProps {
  content: string;
}

const MarkdownMessage = ({ content }: IProps) => {
  return (
    <div>
      {content && (
        <ReactMarkdown
          components={{
            a: ({ href, children }) => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
            p: ({ children }) => <>{children}</>,
            code: ({ children, inline }) => {
              if (inline) {
                return <code className="language-js">{children}</code>;
              }
              return (
                <pre>
                  <code className="language-js">{children}</code>
                </pre>
              );
            },
            ul: ({ children }) => {
              return <ul>{children}</ul>;
            },
            ol: ({ children }) => {
              return <ol>{children}</ol>;
            },
            li: ({ children }) => {
              return <li>{children}</li>;
            },
          }}>
          {content}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default memo(MarkdownMessage);
