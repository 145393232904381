import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import reportWebVitals from './configs/web-vitals/reportWebVitals';

import './styles/index.css';

const chatWidgetElement = document.getElementById('chat-widget');

if (chatWidgetElement) {
  window.addEventListener('DOMContentLoaded', function (e: Event) {
    const root = ReactDOM.createRoot(chatWidgetElement as HTMLElement);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  });
} else {
  console.error('Element with id "chat-widget" not found');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
