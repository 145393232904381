import axios, { AxiosInstance } from 'axios';
import { API_URL } from '../consts';

export type ResponseSuccessKind = 'success';
export type ResponseErrorKind = 'failure';

export const ResponseSuccess = 'success' as ResponseSuccessKind;
export const ResponseError = 'failure' as ResponseErrorKind;

export interface RequestResponse<T> {
  kind: ResponseSuccessKind | ResponseErrorKind;
  body?: T;
  message?: string;
  status?: number;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

export default axiosInstance;
