import { useReducer } from 'react';
import { Message } from '../api/chat/types';

export interface AppState {
  title: string;
  color: string;
  position: string;
  isVisible: boolean;
  welcomeMessage: string;
  messages: Message[];
}

export type ActionType =
  | 'TITLE'
  | 'COLOR'
  | 'POSITION'
  | 'IS_VISIBLE'
  | 'WELCOME_MESSAGE'
  | 'MESSAGES'
  | 'UPDATE_WELCOME_MESSAGE'
  | 'DELETE_WELCOME_MESSAGE';

export interface AppAction {
  type: ActionType;
  payload?: any;
}

const initialState: AppState = {
  title: '',
  color: '',
  position: '',
  isVisible: true,
  welcomeMessage: '',
  messages: [],
};

const useAppReducer = () => {
  const reducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
      case 'TITLE':
        return {
          ...state,
          title: action.payload,
        };
      case 'COLOR':
        return {
          ...state,
          color: action.payload,
        };
      case 'POSITION':
        return {
          ...state,
          position: action.payload,
        };
      case 'WELCOME_MESSAGE':
        return {
          ...state,
          welcomeMessage: action.payload,
        };
      case 'MESSAGES':
        return {
          ...state,
          messages: state.welcomeMessage
            ? [
                {
                  role: 'assistant',
                  content: state.welcomeMessage,
                  type: 'WELCOME_MESSAGE',
                },
                ...action.payload,
              ]
            : action.payload,
        };
      case 'UPDATE_WELCOME_MESSAGE':
        return {
          ...state,
          messages:
            state.messages[0]?.type === 'WELCOME_MESSAGE'
              ? [
                  action.payload,
                  ...state.messages.slice(1, state.messages.length),
                ]
              : [action.payload, ...state.messages],
        };
      case 'DELETE_WELCOME_MESSAGE':
        return {
          ...state,
          messages:
            state.messages[0]?.type === 'WELCOME_MESSAGE'
              ? [...state.messages.slice(1, state.messages.length)]
              : [...state.messages],
        };
      default:
        return state;
    }
  };

  const init = (initialState: AppState): AppState => {
    return initialState;
  };

  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, initialState, init);

  return {
    state,
    dispatch,
  };
};

export default useAppReducer;
