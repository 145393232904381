import React from 'react';
import { AppAction, AppState } from '../hooks/useAppReducer';
import { createContext, useContext } from 'react';

export interface AppStorageContextProps {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
}

export const AppStorageContext = createContext<
  AppStorageContextProps | undefined
>(undefined);

export const useAppStorageContext = (): AppStorageContextProps => {
  const context = useContext(AppStorageContext);
  if (!context) {
    throw new Error(
      'useAppStorageContext must be used within an AppStorageProvider',
    );
  }
  return context;
};
